<template>
  <main id="content" role="main">
    <div class="container py-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">
            {{ erc == 2 ? "RCH-20" : erc == 721 ? "RCH-721" : "Token" }}
            Transfers
          </h1>
          <div class="mt-1"></div>
        </div>
        <div class="d-flex flex-wrap"></div>
      </div>
    </div>
    <div id="overlayMain"></div>
    <div class="container"></div>
    <div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="table-responsive mb-2 mb-md-0">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">
                        {{ getName("Token", "Txn Hash") }}
                      </th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="isAge = !isAge">{{
                          isAge ? "Age" : "Time"
                        }}</a>
                      </th>

                      <th scope="col">
                        {{ getName("Token", "From") }}
                      </th>
                      <th scope="col">
                        {{ getName("Token", "To") }}
                      </th>
                      <th scope="col">
                        {{ erc == 721 ? "TokenId" : getName("Token", "Value") }}
                      </th>
                      <th scope="col">
                        {{ getName("Token", "Token") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-loading="loading">
                    <tr v-for="item in tokensTransactionList" :key="item._id">
                      <td>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          v-on:click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge :timestamp="item.timestamp" :isAge="isAge" />
                      </td>
                      <td>
                        <ItemAddress
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <ItemAddress :address="item.to" :info="item.toInfo" />
                      </td>

                      <td>
                        <span href="#">{{
                          item.ERC == 721
                            ? item.value
                            : formatNumber(item.value, 4)
                        }}</span>
                      </td>

                      <td>
                        <ItemToken
                          :logo="item.token.logo"
                          :address="item.token.address"
                          :tokenName="item.token.tokenName"
                          :symbol="item.token.symbol"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="tokensTransactionList.length" />
              </div>

              <Page
                :execPage="execPage"
                :total="total"
                :currentPage="currentPage"
                :pageSize="pageNum"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import TimeAge from "./components/timeAge.vue";
import ItemAddress from "./components/itemAddress.vue";
import ItemHash from "./components/item_hash.vue";
import ItemToken from "./components/item_token.vue";
import NoData from "./components/noData.vue";
import { getUrlKey } from "../assets/js/utils.js";
export default {
  name: "txsToken",
  data() {
    return {
      currentPage: 1,
      pageNum: 20,
      total: 0,
      tokensTransactionList: [],
      loading: false,
      isAge: true,
      erc: 1,
    };
  },
  components: {
    ItemAddress,
    ItemHash,
    TimeAge,
    ItemToken,
    NoData,
  },
  mounted() {
    this.erc = parseInt(getUrlKey("num"));
    this.currentPage = 1;
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.getTokensTransactionList,
      (info) => {
        this.loading = false;
        if (info.data && info.data.data) {
          this.tokensTransactionList = info.data.data;
        }
        this.total = info.data.count;
      },
      (err) => {
        this.loading = false;
      },
      { page: this.currentPage, pageNum: this.pageNum, erc: this.erc }
    );
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showTransaction: function (hash, symbol) {
      // location.href = "/tx/tokentx.html?h=" + hash + "&s=" + symbol;
      route.push({
        path: "/txTokenDetail",
        query: {
          h: hash,
          s: symbol,
        },
      });
    },
    showAddress: function (address) {
      // location.href = "/address/?a=" + address;
      route.push({
        path: "/address",
        query: {
          a: address,
        },
      });
    },
    showToken: function (address) {
      // location.href = "/token/index.html?c=" + address;
      route.push({
        path: "/tokenDetail",
        query: {
          c: address,
        },
      });
    },
    showBlock: function (blockNum) {
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
    execPage: function (page) {
      this.currentPage = parseInt(this.currentPage) + parseInt(page);
      if (isNaN(this.currentPage)) {
        this.currentPage = 1;
      }
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      let totalPage = parseInt(this.total / this.pageNum) + 1;
      if (this.currentPage > totalPage) {
        this.currentPage = totalPage;
        return;
      }
      this.loading = true;
      PostNet.PostWith(
        PostNetUrl.getTokensTransactionList,
        (info) => {
          this.loading = false;
          this.tokensTransactionList = info.data.data;
          this.total = info.data.count;
        },
        (res) => {
          this.loading = false;
        },
        { page: this.currentPage, pageNum: this.pageNum }
      );
    },
  },
};
</script>
